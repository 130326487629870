<template>
    <div>
        <Row class="p-b-5">
          <label class="title">派单方</label>
          <i-col span="24">
                <Select v-model="searchModel.companyId" size="small" placeholder="全部代理商" clearable @on-change="reloadChange">
                    <Option v-for="item in channellist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
          </i-col>
      </Row>

      <Row class="p-b-5">
          <label class="title">分析周期</label>
            <i-col span="24">
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.startDate" format="yyyy-MM-dd" type="date" placeholder="开始日期" :options="startDateOptions" @on-change='startDateChange' :clearable="false" style="width: 150px"></DatePicker>
                至
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.endDate" format="yyyy-MM-dd" type="date" placeholder="结束日期" :options="endDateOptions" @on-change='endDateChange' :clearable="false" style="width: 150px"></DatePicker>
            </i-col>
      </Row>

      <Row class="p-t-20 p-b-5">
          <i-col span="12"><h3>作业类型</h3></i-col>
          <i-col span="12" class="text-right">
              (<span class="text-red">+</span><span class="text-green">-</span>比较上周期变化)
          </i-col>
      </Row>
      <Row class="table-title p-t-2">
          <i-col span="6" class="p-l-5">类型</i-col>
          <i-col span="4">作业数</i-col>
          <i-col span="7">及时完成率</i-col>
          <i-col span="7">合格率</i-col>
      </Row>
      <div v-for="(typeItem,index) in workTypeData" :key="index" class="p-t-2" style="cursor: pointer;"
        :class="computedRowStyle(typeItem.id,0,index)" @click="handleClickWorkType(typeItem.id)">
        <Row>
          <i-col span="6" class="p-l-5">{{typeItem.name}}</i-col>
          <i-col span="4">{{typeItem.workNum}}</i-col>
          <i-col span="7">{{typeItem.completeRate}}%(
            <span v-if="typeItem.completeRateChain===9999">-</span>
            <span v-else :class="typeItem.completeRateChain>0?'text-red':'text-green'">{{typeItem.completeRateChain>0?'+':''}}{{typeItem.completeRateChain.toFixed(0)}}%</span>)
          </i-col>
          <i-col span="7">{{typeItem.passRate}}%(
            <span v-if="typeItem.passRateChain===9999">-</span>
            <span v-else :class="typeItem.passRateChain>0?'text-red':'text-green'">{{typeItem.passRateChain>0?'+':''}} {{typeItem.passRateChain.toFixed(0)}}%</span>)
          </i-col>
        </Row>
      </div>

      <Row class="p-t-20 p-b-5">
          <i-col span="24"><h3>小组概况</h3></i-col>
      </Row>
      <Row class="table-title p-t-2">
          <i-col span="6" class="p-l-5">姓名</i-col>
          <i-col span="4">作业数</i-col>
          <i-col span="7">及时完成率</i-col>
          <i-col span="7">合格率</i-col>
      </Row>

      <div v-for="(groupItem,index) in workGroupData" :key="'g'+index" class="p-t-2" style="cursor: pointer;"
        :class="computedRowStyle(0,groupItem.id,index)" @click="handleClickWorkGroup(groupItem.id)">
        <Row>
          <i-col span="6" class="p-l-5">{{groupItem.name}}</i-col>
          <i-col span="4">{{groupItem.workNum}}</i-col>
          <i-col span="7">{{groupItem.completeRate}}%(
            <span v-if="groupItem.completeRateChain===9999">-</span>
            <span v-else :class="groupItem.completeRateChain>0?'text-red':'text-green'">{{groupItem.completeRateChain>0?'+':''}}{{groupItem.completeRateChain.toFixed(0)}}%</span>)
          </i-col>
          <i-col span="7">{{groupItem.passRate}}%(
            <span v-if="groupItem.passRateChain===9999">-</span>
            <span v-else :class="groupItem.passRateChain>0?'text-red':'text-green'">{{groupItem.passRateChain>0?'+':''}} {{groupItem.passRateChain.toFixed(0)}}%</span>)
          </i-col>
        </Row>
      </div>
      <div class="paging_style">
          <Page size="small" :total="workGroupTotal" :page-size="groupQuery.pageSize" :current="groupQuery.pageNum" show-total @on-change="changePage"></Page>
      </div>
    </div>
</template>

<script>
import { listWorkItemGroupByType, listWorkItemGroupByWorkGroup } from '@/api/dw/maintainRole'
import { getCompanyPage } from '@/api/os/company'
import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return ({
      searchModel: {
        startDate: new Date().getFullYear() + '-01-01',
        endDate: new Date().getFullYear() + '-12-31',
        companyId: null,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        supplierId: this.$store.getters.token.userInfo.companyId,
        type: null,
        workGroupId: null
      },
      groupQuery: {
        pageSize: 15,
        pageNum: 1
      },
      startDateOptions: {},
      endDateOptions: {},

      channellist: [], // 全部代理商
      workTypeData: [], // 作业类型数据
      workGroupData: [], // 作业小组数据
      workGroupTotal: 0
    })
  },
  mounted () {
    this.startDateChange()
    this.endDateChange()

    const channelquery = {
      pageNumber: 1,
      pageSize: 100,
      companyId: this.searchModel.publisherId,
      type: JSON.stringify([2])
    }
    getCompanyPage(channelquery).then(res => {
      this.channellist = res.list
    })

    this.$nextTick(() => {
      this.reloadChange()
    })
  },
  methods: {
    initPageData () {
      listWorkItemGroupByType(this.searchModel).then(res => {
        this.workTypeData = res
      })

      this.groupQuery.pageNum = 1
      this.groupQuery = Object.assign(this.groupQuery, this.searchModel)
      this.loadWorkGroupData()
    },
    loadWorkGroupData () {
      listWorkItemGroupByWorkGroup(this.groupQuery).then(res => {
        this.workGroupTotal = res.totalRow
        this.workGroupData = res.list
      })
    },
    changePage (currentPage) {
      this.groupQuery.pageNum = currentPage
      this.loadWorkGroupData()
    },
    startDateChange (e) {
      this.endDateOptions = {
        disabledDate: date => {
          const startTime = this.searchModel.startDate ? new Date(this.searchModel.startDate).valueOf() : 0
          return date && (date.valueOf() < startTime)
        }
      }

      if (e) {
        this.reloadChange()
      }
    },
    endDateChange (e) {
      const endTime = this.searchModel.endDate ? new Date(this.searchModel.endDate).valueOf() - 1 * 24 * 60 * 60 * 1000 : 0
      this.startDateOptions = {
        disabledDate (date) {
          return date && (date.valueOf() > endTime)
        }
      }

      if (e) {
        this.reloadChange()
      }
    },
    reloadChange () {
      this.searchModel.startDate = ParseDate(this.searchModel.startDate)
      this.searchModel.endDate = ParseDate(this.searchModel.endDate)
      this.searchModel.type = null
      this.searchModel.workGroupId = null

      this.$emit('updatePageData', this.searchModel)
      this.initPageData()
    },
    computedRowStyle (typeId, groupId, index) {
      if (typeId === this.searchModel.type || groupId === this.searchModel.workGroupId) {
        return 'table-row-active'
      } else {
        return (index % 2 === 0) ? 'table-row-1' : 'table-row-2'
      }
    },
    handleClickWorkType (wordTypeId) {
      this.searchModel.type = (this.searchModel.type === wordTypeId) ? null : wordTypeId
      this.searchModel.workGroupId = null

      this.groupQuery.pageNum = 1
      this.groupQuery = Object.assign(this.groupQuery, this.searchModel)
      this.loadWorkGroupData()
      this.$emit('updatePageData', this.searchModel)
    },
    handleClickWorkGroup (wordGroupId) {
      this.searchModel.workGroupId = (this.searchModel.workGroupId === wordGroupId) ? null : wordGroupId
      this.$emit('updatePageData', this.searchModel)
    }
  }
}
</script>
